@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	scroll-behavior: smooth;
	transition: 300ms all;
}
.forTransitionClass {
	transition: none;
	color: orangered;
}
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
.montserrat {
	font-family: "Montserrat", sans-serif;
}

.overflowScrollHide::-webkit-scrollbar {
	width: 4px;
}

/* Track */
.overflowScrollHide::-webkit-scrollbar-track {
	box-shadow: inset 0 0 3px lightgray;
	border-radius: 10px;
}

/* Handle */
.overflowScrollHide::-webkit-scrollbar-thumb {
	background: orangered;
	border-radius: 10px;
}

.footerBgImg {
	background-image: url("./assets/kol.webp");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top;
	z-index: 1;
}
/* {sell?.text.length >= 90 ? sell?.text.slice(0, 90).concat('  ...') : sell?.text} */
.footerBgImg::after {
	z-index: -1;
	content: "";
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background-color: rgb(0, 0, 0, 0.6);
}
.productModalShadow::before {
	content: "";
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100vh;
	background-color: rgb(0, 0, 0, 0.8);
	z-index: 5;
}
.truckAnimation {
	animation: truck 10s linear infinite;
}
.before:checked::before {
	content: "✔";
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #fff;
	font-size: 10px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}
@keyframes truck {
	from {
		margin-left: 0%;
	}
	to {
		margin-left: 110%;
	}
}
