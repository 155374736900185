.mySwiper2 {
    width: 70%;
    user-select: none;
}

.mySwiper2 .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mySwiper {
    margin-top: 2rem;
    box-sizing: border-box;
    padding: 10px 0;
    color: gray
}

.mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
    filter: blur(1px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.mySwiper .swiper-slide-thumb-active {
    opacity: 1;
    filter: blur(0px);
    animation: rotatechandlier 1.2s linear infinite;
}

.swiper-slide img {
    display: block;
    object-fit: cover;
}

@keyframes rotatechandlier {
    0% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(1deg);
    }

    50% {
        transform: rotate(0);
    }

    75% {
        transform: rotate(-1deg);
    }

    100% {
        transform: rotate(0);
    }
}

svg {
    fill: currentColor;
    height: auto;
    max-width: 66vmin;
    transform-origin: center;

}